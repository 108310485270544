import { render, staticRenderFns } from "./lawCard.vue?vue&type=template&id=8200e89c&scoped=true"
import script from "./lawCard.vue?vue&type=script&lang=js"
export * from "./lawCard.vue?vue&type=script&lang=js"
import style0 from "./lawCard.vue?vue&type=style&index=0&id=8200e89c&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8200e89c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/3440258-cci-44866527-275603/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8200e89c')) {
      api.createRecord('8200e89c', component.options)
    } else {
      api.reload('8200e89c', component.options)
    }
    module.hot.accept("./lawCard.vue?vue&type=template&id=8200e89c&scoped=true", function () {
      api.rerender('8200e89c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Ana/pages/analysis/components/lawCard.vue"
export default component.exports